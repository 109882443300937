import { ErrorMessage } from 'components/organisms/PageErrorMessage';
import { useRouter } from 'next/router';

const Custom404Page = () => {
  const { locale } = useRouter();

  return (
    <div className="mb-[-6rem]">
      <ErrorMessage
        code={
          locale?.startsWith('ja') ? 'ページが見つかりません' : 'Page Not Found'
        }
        title={
          locale?.startsWith('ja')
            ? 'ご指定のページが移動または\n削除された可能性があります。'
            : 'The page you specified may have been moved or deleted.'
        }
      />
    </div>
  );
};

export default Custom404Page;
