import { Box, Text } from '@chakra-ui/react';
import { Button } from 'ui';
import { useRouter } from 'next/router';
interface IErrorMessageProps {
  title?: string;
  code?: string;
}

export const ErrorMessage = ({ title, code }: IErrorMessageProps) => {
  const { locale } = useRouter();

  return (
    <div className="flex items-center justify-center bg-[#1B3A7F] py-[120px] px-6 lg:h-[670px] lg:py-0">
      <div className="max-w-[490px]">
        <Text
          className=" font-Poppins text-xl font-semibold leading-[30px] text-white "
          as="strong"
        >
          {code}
        </Text>
        <Text
          className="mt-[25px] whitespace-pre-line font-Rubik text-xl font-bold  leading-[38px] text-white lg:text-[32px]"
          as="h3"
        >
          {title}
        </Text>
        <Text className="mt-6 font-Poppins text-base text-white">
          {locale?.startsWith('ja')
            ? '下記のリンクより目的のページをお探しください。'
            : 'Here are some helpful links instead'}
        </Text>
        <Box className="lg:flex lg:gap-6">
          <Button
            className="mt-4 border-white !text-white"
            href="/"
            btnVarient="link"
            type="secondary"
          >
            {locale?.startsWith('ja') ? 'トップ' : 'Home'}
          </Button>
          <Button
            className="mt-4 border-white !text-white"
            href="/service"
            btnVarient="link"
            type="secondary"
          >
            {locale?.startsWith('ja') ? 'サービス内容' : 'Service'}
          </Button>
          <Button
            className="mt-4 border-white !text-white"
            href="/contact"
            btnVarient="link"
            type="secondary"
          >
            {locale?.startsWith('ja') ? 'お問い合わせ' : 'Contact us'}
          </Button>
        </Box>
      </div>
    </div>
  );
};
